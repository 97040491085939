<loader size="lg" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
<div
  *ngIf="!shouldShowUploadedBulkOrders && !shouldShowCheckoutPage"
  class="container"
  [ngClass]="{ 'cart-loading-overlay': isLoading }"
>
  <p *ngIf="closingMessage" class="market-unavailability-message">
    {{ closingMessage }}
  </p>
  <p class="cart-title heading2--extra-bold">
    {{ trans('CART.TITLE') }}
    <span class="cart-title__product-count heading2--bold">
      ({{ cartData?.totalQuantity || 0 }} {{ trans('CART.PRODUCT') }})
    </span>
  </p>
  <app-selected-country-tooltip></app-selected-country-tooltip>
  <div class="row no-gutters mt-1 mb-4">
    <div class="col-12 col-lg-8">
      <app-cart-products-table
        [products]="cartData?.products || []"
        [shouldShowQuantityDiscount]="shouldShowQuantityDiscount"
        (cartProductsUpdated)="onCartUpdated()"
        (quantityChanged)="onQuantityChanged()"
      ></app-cart-products-table>
    </div>
    <div class="col-12 col-md-8 col-lg-4 d-md-block cart-actions">
      <app-cart-bulk-orders-actions
        class="cart-actions__bulk-orders"
        [cartData]="cartData"
        [disableActions]="isMarketClosed || isDuplicateAccount"
        [uploadedVariantsHashMap]="uploadedVariantsHashMap"
        (bulkOrdersSheetUploadSuccessful)="showUploadedBulkOrders($event)"
      ></app-cart-bulk-orders-actions>
      <ng-container *ngIf="cartData?.products?.length">
        <p class="or-separator caption1--bold">
          {{ trans('CART.OR_SEPARATOR') }}
        </p>
        <app-cart-summary
          class="cart-actions__summary"
          [cartData]="cartData"
          [isMarketClosed]="isMarketClosed"
          [isDuplicateAccount]="isDuplicateAccount"
          [shouldShowQuantityDiscount]="shouldShowQuantityDiscount"
          (submitOrderButtonClicked)="onCheckout()"
        ></app-cart-summary>
      </ng-container>
    </div>
  </div>
</div>
<div *ngIf="shouldShowUploadedBulkOrders" class="container">
  <app-bulk-orders
    [orders]="uploadedOrderLines"
    [uploadedVariantsHashMap]="uploadedVariantsHashMap"
    (submitted)="orderSubmitted($event)"
    (back)="shouldShowUploadedBulkOrders = false"
  ></app-bulk-orders>
</div>
<div *ngIf="shouldShowCheckoutPage" class="container">
  <app-order
    [isOrderNow]="false"
    [cartData]="cartData"
    [products]="checkoutProducts"
    (submitted)="orderSubmitted($event)"
    (back)="onCloseCheckoutPage()"
  ></app-order>
</div>
